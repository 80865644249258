import React from 'react';

export function H2(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M27.226 14c-.74 0-1.341.591-1.341 1.321s.6 1.321 1.341 1.321h3.564v12.036H16.184V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.34-1.322H9.34C8.601 14 8 14.591 8 15.321s.6 1.321 1.341 1.321h3.564v26.716H9.341c-.74 0-1.341.591-1.341 1.32C8 45.409 8.6 46 9.341 46h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.34-1.321h-3.59V31.32H30.79v12.037h-3.564c-.74 0-1.341.591-1.341 1.32 0 .73.6 1.322 1.341 1.322h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.341-1.321h-3.59V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.341-1.322H27.226z"
        clipRule="evenodd"
      />
      <path d="M42.257 46v-1.922l5.726-5.95c.62-.668 1.108-1.244 1.462-1.727.355-.484.604-.925.75-1.324.144-.399.217-.802.217-1.208 0-.737-.226-1.355-.677-1.853-.45-.507-1.103-.76-1.957-.76-.902 0-1.595.272-2.078.817-.483.537-.725 1.29-.725 2.256h-2.948l-.024-.069c-.032-.99.185-1.88.653-2.67.475-.798 1.151-1.427 2.03-1.888.885-.468 1.936-.702 3.153-.702 1.16 0 2.158.203 2.996.61.845.407 1.494.97 1.945 1.692.459.721.688 1.554.688 2.498 0 .959-.286 1.883-.857 2.774-.572.882-1.438 1.914-2.598 3.096l-3.926 4.04.024.057h5.267l.181-1.738H54V46H42.257z" />
    </svg>
  );
}
