import React from 'react';

export function OrderedList(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={15}
      height={15}
      fill="currentColor"
      {...props}
    >
      <rect width="20" height="2" x="8" y="20" rx="1" />
      <rect width="20" height="2" x="8" y="14" rx="1" />
      <rect width="20" height="2" x="8" y="8" rx="1" />
      <path d="M2.604 11v-.454h1.174V7.259l-1.095.117-.053-.466 1.257-.146h.48v3.782h1.037V11h-2.8zM2.677 17l-.056-.463 1.43-1.556c.26-.28.407-.454.442-.518.037-.066.072-.139.106-.217a.868.868 0 00.052-.343c0-.267-.056-.456-.17-.565-.113-.11-.301-.164-.565-.164-.164 0-.296.01-.395.032a.586.586 0 00-.258.123c-.13.107-.194.331-.194.671H2.48c0-.867.464-1.3 1.392-1.3.445 0 .78.113 1.002.34.223.224.334.497.334.82 0 .322-.059.574-.176.755a3.004 3.004 0 01-.357.466l-.208.217-1.175 1.245h1.893V17H2.677zM4.663 19.813c0-.426-.222-.64-.665-.64-.23 0-.39.022-.48.065a.455.455 0 00-.194.156c-.082.129-.123.33-.123.603H2.64c0-.357.06-.63.181-.817a.885.885 0 01.472-.381c.195-.067.424-.1.686-.1.38 0 .681.092.902.276.223.181.334.458.334.829 0 .285-.054.505-.161.659-.106.152-.279.248-.519.287.256.053.447.161.574.325.127.164.19.432.19.803 0 .371-.112.662-.339.873-.227.209-.55.313-.97.313-.318 0-.571-.041-.759-.125a.874.874 0 01-.424-.41c-.098-.19-.163-.454-.197-.792h.563c.023.348.099.578.225.692.07.062.154.105.25.129.095.021.22.032.374.032.249 0 .43-.064.545-.19.116-.127.173-.34.173-.636 0-.297-.054-.494-.164-.59a.688.688 0 00-.398-.172 4.083 4.083 0 00-.457-.026v-.437c.312 0 .523-.025.633-.076a.563.563 0 00.237-.185c.049-.074.073-.23.073-.465z" />
    </svg>
  );
}
