import React from 'react';

export function H1(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M27.226 14c-.74 0-1.341.591-1.341 1.321s.6 1.321 1.341 1.321h3.564v12.036H16.184V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.34-1.322H9.34C8.601 14 8 14.591 8 15.321s.6 1.321 1.341 1.321h3.564v26.716H9.341c-.74 0-1.341.591-1.341 1.32C8 45.409 8.6 46 9.341 46h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.34-1.321h-3.59V31.32H30.79v12.037h-3.564c-.74 0-1.341.591-1.341 1.32 0 .73.6 1.322 1.341 1.322h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.341-1.321h-3.59V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.341-1.322H27.226z"
        clipRule="evenodd"
      />
      <path d="M44.044 46v-1.798l2.6-.35V31.826L44 31.849V30.12L49.4 29v14.852l2.6.35V46h-7.956z" />
    </svg>
  );
}
