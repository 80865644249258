import React from 'react';

export function H5(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M27.226 14c-.74 0-1.341.591-1.341 1.321s.6 1.321 1.341 1.321h3.564v12.036H16.184V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.34-1.322H9.34C8.601 14 8 14.591 8 15.321s.6 1.321 1.341 1.321h3.564v26.716H9.341c-.74 0-1.341.591-1.341 1.32C8 45.409 8.6 46 9.341 46h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.34-1.321h-3.59V31.32H30.79v12.037h-3.564c-.74 0-1.341.591-1.341 1.32 0 .73.6 1.322 1.341 1.322h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.341-1.321h-3.59V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.341-1.322H27.226z"
        clipRule="evenodd"
      />
      <path d="M47.889 46c-.92 0-1.754-.18-2.502-.541a4.293 4.293 0 01-1.775-1.588c-.435-.699-.638-1.547-.61-2.544l.022-.057 2.534-.093c0 .814.214 1.45.642 1.911.434.453.998.68 1.689.68.798 0 1.4-.304 1.807-.91.413-.614.62-1.427.62-2.44s-.214-1.834-.641-2.463c-.42-.63-1.03-.944-1.829-.944-.684 0-1.197.126-1.54.38-.342.253-.587.614-.737 1.082l-2.342-.196.898-9.277h8.65v3.603h-1.774l-.246-1.197H46.37l-.47 4.224a3.94 3.94 0 011.058-.599c.406-.161.862-.25 1.368-.265 1.476-.015 2.624.48 3.443 1.485.82 1.005 1.23 2.387 1.23 4.144 0 1.097-.196 2.068-.588 2.912a4.606 4.606 0 01-1.732 1.98c-.756.475-1.686.713-2.791.713z" />
    </svg>
  );
}
