import React from 'react';

export function H3(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M27.226 14c-.74 0-1.341.591-1.341 1.321s.6 1.321 1.341 1.321h3.564v12.036H16.184V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.34-1.322H9.34C8.601 14 8 14.591 8 15.321s.6 1.321 1.341 1.321h3.564v26.716H9.341c-.74 0-1.341.591-1.341 1.32C8 45.409 8.6 46 9.341 46h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.34-1.321h-3.59V31.32H30.79v12.037h-3.564c-.74 0-1.341.591-1.341 1.32 0 .73.6 1.322 1.341 1.322h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.341-1.321h-3.59V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.341-1.322H27.226z"
        clipRule="evenodd"
      />
      <path d="M48.439 46c-1.037 0-1.971-.185-2.803-.556-.833-.37-1.487-.9-1.964-1.589-.476-.696-.7-1.532-.67-2.508l.023-.068h2.758c0 .749.235 1.358.704 1.827.477.462 1.127.692 1.952.692.84 0 1.498-.23 1.975-.692.484-.469.726-1.139.726-2.009 0-1.778-.927-2.666-2.78-2.666h-1.862v-2.18h1.907c.87 0 1.502-.222 1.895-.669.394-.446.59-1.055.59-1.827 0-.81-.208-1.434-.624-1.873-.416-.446-1.025-.669-1.827-.669-.742 0-1.343.22-1.805.658-.453.431-.68 1.003-.68 1.714h-2.77l-.022-.068c-.023-.855.185-1.623.624-2.304.446-.681 1.067-1.218 1.861-1.611.802-.401 1.729-.602 2.78-.602 1.612 0 2.902.409 3.87 1.226.97.81 1.453 1.963 1.453 3.46 0 .735-.212 1.423-.635 2.066-.416.643-1.044 1.154-1.884 1.532.946.326 1.642.833 2.088 1.521.454.681.681 1.46.681 2.338 0 1.006-.238 1.872-.715 2.599-.477.726-1.135 1.286-1.975 1.68-.84.385-1.797.578-2.871.578z" />
    </svg>
  );
}
