import React from 'react';

export function Sup(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M9.101 38.82c-.357-1.097.27-2.053 1.253-2.49.983-.438 2.404-.39 3.013.699 2.019 3.609 4.76 4.335 7.669 4.335 3.6 0 6.263-1.588 6.263-4.335 0-2.21-1.331-3.37-4.932-4.464l-2.466-.751c-5.238-1.61-7.857-4.186-7.857-8.392 0-5.645 4.802-9.422 11.61-9.422 5.793 0 9.45 2.205 11.057 5.524.458.948.477 2.05-.728 2.996-1.205.947-2.438.534-3.442-.52-2.634-2.762-4.284-3.364-6.843-3.364-3.863 0-5.914 1.996-5.914 4.185 0 1.996 1.44 3.24 4.845 4.27l2.531.774c5.37 1.61 7.944 4.077 7.944 8.198 0 5.451-4.43 9.937-12.155 9.937-5.521 0-10.595-3.326-11.848-7.18z"
        clipRule="evenodd"
      />
      <path d="M39.257 22v-1.922l5.726-5.95c.62-.668 1.108-1.244 1.462-1.727.355-.484.604-.925.75-1.324.144-.399.217-.802.217-1.208 0-.737-.226-1.355-.677-1.853-.45-.507-1.103-.76-1.957-.76-.902 0-1.595.272-2.078.817-.483.537-.725 1.29-.725 2.256h-2.948l-.024-.069c-.032-.99.185-1.88.653-2.67.475-.798 1.151-1.428 2.03-1.888C42.57 5.234 43.621 5 44.838 5c1.16 0 2.158.203 2.996.61.845.407 1.494.97 1.945 1.692.459.721.688 1.554.688 2.498 0 .959-.286 1.883-.857 2.773-.572.883-1.438 1.915-2.598 3.097l-3.926 4.04.024.057h5.267l.181-1.738H51V22H39.257z" />
    </svg>
  );
}
