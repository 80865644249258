import React from 'react';

export function H6(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 60 60"
      width={18}
      height={18}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M27.226 14c-.74 0-1.341.591-1.341 1.321s.6 1.321 1.341 1.321h3.564v12.036H16.184V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.34-1.322H9.34C8.601 14 8 14.591 8 15.321s.6 1.321 1.341 1.321h3.564v26.716H9.341c-.74 0-1.341.591-1.341 1.32C8 45.409 8.6 46 9.341 46h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.34-1.321h-3.59V31.32H30.79v12.037h-3.564c-.74 0-1.341.591-1.341 1.32 0 .73.6 1.322 1.341 1.322h10.433c.74 0 1.341-.592 1.341-1.321 0-.73-.6-1.321-1.341-1.321h-3.59V16.642h3.59c.74 0 1.341-.591 1.341-1.32 0-.73-.6-1.322-1.341-1.322H27.226z"
        clipRule="evenodd"
      />
      <path d="M48.685 46c-1.105 0-2.086-.265-2.943-.794-.858-.53-1.53-1.294-2.015-2.293-.484-1.006-.727-2.216-.727-3.631V36.66c0-1.634.269-3.022.806-4.165.537-1.142 1.283-2.008 2.238-2.598.955-.598 2.051-.897 3.29-.897.589 0 1.148.06 1.678.182.53.12 1.022.29 1.477.51l-.514 2.1a7.531 7.531 0 00-1.209-.386 5.684 5.684 0 00-1.376-.159c-1.105 0-1.959.393-2.563 1.18-.604.78-.917 1.945-.94 3.496l.034.056c.388-.393.869-.7 1.443-.919a5.221 5.221 0 011.914-.34c.97 0 1.809.238 2.517.715.71.476 1.254 1.13 1.634 1.963.38.824.571 1.766.571 2.826 0 1.127-.224 2.126-.671 2.996a5.085 5.085 0 01-1.858 2.042c-.798.492-1.727.738-2.786.738zm-.034-2.202c.783 0 1.399-.344 1.846-1.032.456-.696.683-1.551.683-2.565 0-.968-.239-1.763-.716-2.383-.47-.628-1.134-.942-1.992-.942-.612 0-1.149.12-1.611.363a2.622 2.622 0 00-1.052.987v1.192c0 1.407.265 2.489.794 3.246.53.756 1.212 1.134 2.048 1.134z" />
    </svg>
  );
}
